import { useTranslation } from 'react-i18next';
import { flatten } from 'lodash';

import { LoadingContact } from 'shared/types/loadingContact';
import { mapAddressToLoadingContact } from 'shared/parsers/addressBookParser';
import { useAddressBook } from './addressBook';

enum PredictionSource {
  AddressBook,
  Google,
  None,
}

export type PlacePrediction = {
  placeId: string;
  description: string;
  source: PredictionSource;
  sourceTranslation: string;
};

export const emptyPlacePrediction: PlacePrediction = {
  placeId: '',
  description: '',
  source: PredictionSource.None,
  sourceTranslation: '',
};

type UsePlacesReturnType = {
  fetchLoadingAddressSuggestions: (query: string) => Promise<PlacePrediction[]>;
  fetchLoadingContactByPlaceId: (prediction: PlacePrediction) => Promise<LoadingContact | null>;
};

export const usePlaces = (): UsePlacesReturnType => {
  const { fetchAddressBook, fetchAddress } = useAddressBook();
  const { t } = useTranslation();

  //
  // Address-book in Logtech Holding backend
  //

  const fetchLoadingAddressSuggestionsFromAddressBook = (
    query: string,
  ): Promise<PlacePrediction[]> => {
    if (!query) {
      return Promise.resolve([]);
    }

    return new Promise((resolve) => {
      fetchAddressBook({
        keyword: query,
        page: 1,
        sizePerPage: 3,
      })
        .then((response) => {
          const addresses = response.isSuccessful ? response.payload?.contextObjects : null;

          if (addresses) {
            resolve(
              addresses.map((address) => {
                return {
                  placeId: address.id.toString(),
                  description: address.addressName,
                  source: PredictionSource.AddressBook,
                  sourceTranslation: t('RESULTS_FROM_ADDRESS_BOOK'),
                };
              }),
            );
          } else {
            resolve([]);
          }
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  const fetchAddressFromAddressBook = (placeId: string): Promise<LoadingContact | null> => {
    const id = parseInt(placeId, 10);

    if (Number.isNaN(id)) {
      return Promise.resolve(null);
    }

    return fetchAddress({ id })
      .then((response) => {
        const address = response.isSuccessful ? response.payload : null;

        if (address) {
          return Promise.resolve(mapAddressToLoadingContact(address));
        }

        return Promise.resolve(null);
      })
      .catch(() => {
        return Promise.resolve(null);
      });
  };

  //
  // Google Places API
  //

  const fetchLoadingAddressSuggestionsFromGoogle = (query: string): Promise<PlacePrediction[]> => {
    if (!query) {
      return Promise.resolve([]);
    }

    const autocomplete = new google.maps.places.AutocompleteService();

    const request: google.maps.places.AutocompletionRequest = {
      input: query,
      // Tallinn:
      location: new google.maps.LatLng(59.4034465747615, 24.734107909974963),
      // ~Nordics:
      radius: 350000,
      types: ['establishment', 'geocode'],
    };

    return new Promise<PlacePrediction[]>((resolve, reject) => {
      autocomplete.getPlacePredictions(request, (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(new Error(`Google places API getPlacePredictions returned status=[${status}]`));
        } else if (!predictions) {
          resolve([]);
        } else {
          resolve(
            predictions
              .filter((prediction) => prediction.place_id && prediction.description)
              .map((prediction) => ({
                placeId: prediction.place_id,
                description: prediction.description,
                source: PredictionSource.Google,
                sourceTranslation: t('RESULTS_FROM_GOOGLE_MAPS'),
              })),
          );
        }
      });
    });
  };

  const mapGooglePlaceResultToLoadingContact = (
    placeResult: google.maps.places.PlaceResult,
  ): LoadingContact | null => {
    try {
      const result: LoadingContact = {};

      if (!placeResult.address_components) {
        return null;
      }

      let streetNumber: string | null = null;
      let route: string | null = null;
      let administrativeAreaLevel1: string | null = null;
      let administrativeAreaLevel2: string | null = null;

      placeResult.address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          streetNumber = component.long_name ?? component.short_name;
        } else if (component.types.includes('route')) {
          route = component.long_name ?? component.short_name;
        } else if (component.types.includes('locality')) {
          result.city = component.long_name ?? component.short_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          administrativeAreaLevel1 = component.long_name ?? component.short_name;
        } else if (component.types.includes('administrative_area_level_2')) {
          administrativeAreaLevel2 = component.long_name ?? component.short_name;
        } else if (component.types.includes('country')) {
          result.country = component.short_name;
        } else if (component.types.includes('postal_code')) {
          result.postCode = (component.long_name ?? component.short_name).replace(' ', '');
        }
      });

      if (result.country !== 'US') {
        result.addressLine1 = `${route ?? ''} ${streetNumber ?? ''}`;
      } else {
        result.addressLine1 = `${streetNumber ?? ''} ${route ?? ''}`;
      }

      // city isn't always present e.g. "Keri saar" - fall back to higher level(s) in this case.
      if (!result.city && administrativeAreaLevel2) {
        result.city = administrativeAreaLevel2;
      }

      // city isn't always present e.g. "Keri saar" - fall back to higher level(s) in this case.
      if (!result.city && administrativeAreaLevel1) {
        result.city = administrativeAreaLevel1;
      }

      const isCompany =
        placeResult.types?.includes('establishment') ||
        placeResult.types?.includes('point_of_interest');

      if (isCompany) {
        result.companyName = placeResult.name;
        result.phone = placeResult.international_phone_number;
      }

      if (placeResult.geometry?.location) {
        result.lat = `${placeResult.geometry?.location.lat() ?? ''}`;
        result.lng = `${placeResult.geometry?.location.lng() ?? ''}`;
      }

      return { ...result };
    } catch (_) {
      // Not a blocker issue, can be ignored. Would be good to log in the future to be able to improve parsing.
      return null;
    }
  };

  const fetchAddressFromGoogle = (placeId: string): Promise<LoadingContact | null> => {
    const poweredByGoogleDiv = document.getElementById(
      'Logtech HoldingPoweredByGoogleDiv',
    ) as HTMLDivElement;
    if (!poweredByGoogleDiv) {
      throw Error(
        'Maps attribution needed: https://developers.google.com/places/web-service/policies',
      );
    }

    const placeDetails = new google.maps.places.PlacesService(poweredByGoogleDiv);

    return new Promise<LoadingContact | null>((resolve, reject) => {
      placeDetails.getDetails({ placeId }, (result, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(new Error(`Google places API returned status=[${status}]`));
        } else if (!result) {
          resolve(null);
        } else {
          resolve(mapGooglePlaceResultToLoadingContact(result));
        }
      });
    });
  };

  //
  // Public functions
  //

  const fetchLoadingAddressSuggestions = (query: string): Promise<PlacePrediction[]> => {
    return Promise.all([
      fetchLoadingAddressSuggestionsFromAddressBook(query),
      fetchLoadingAddressSuggestionsFromGoogle(query),
    ])
      .then((arrayOfArrays) => {
        return flatten(arrayOfArrays);
      })
      .catch(() => {
        return [];
      });
  };

  const fetchLoadingContactByPlaceId = (
    prediction: PlacePrediction,
  ): Promise<LoadingContact | null> => {
    if (prediction.source === PredictionSource.Google) {
      return fetchAddressFromGoogle(prediction.placeId);
    }

    if (prediction.source === PredictionSource.AddressBook) {
      return fetchAddressFromAddressBook(prediction.placeId);
    }

    throw Error('Unknown address prediction search');
  };

  return {
    fetchLoadingAddressSuggestions,
    fetchLoadingContactByPlaceId,
  };
};

export default usePlaces;
