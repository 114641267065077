import React, { FC, ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';
import { routeClaims, routePriceRequests, routeShipments } from 'routes';
import Clipboard from 'react-clipboard.js';

import { BaseProps } from 'shared/types';
import { useTranslation } from 'react-i18next';
import {
  ClaimIcon,
  Co2LeafIcon,
  CopyIcon,
  DataIcon,
  Dialog,
  Indicator,
  InfoIcon,
  QuestionMarkIcon,
  ShipmentDocuments,
  ShipmentStatusOverview,
  TruckIcon,
  Typography,
} from 'components';

import { theme } from 'theme';
import {
  ShipmentDocument,
  ShipmentEvent,
  ShipmentPrice,
  ShipmentPriceType,
  ShipmentStatus,
} from 'shared/types/shipments/shipments';
import Box from '@material-ui/core/Box';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import { generateKey } from 'shared/functions/generateKey';
import { useUser } from 'shared/hooks/useUser';
import { useCommonStyles } from 'shared/styles/common';
import { useFeatures } from 'shared/hooks/useFeatures';
import clsx from 'clsx';
import { hasPermission } from 'shared/functions/hasPermission';
import { UserAction, UserActionType } from 'shared/constants/user/userRights';
import Tooltip from '@material-ui/core/Tooltip';
import LabelIcon from '../Icons/LabelIcon/LabelIcon';
import NoteIcon from '../Icons/NoteIcon/NoteIcon';
import PodIcon from '../Icons/PodIcon/PodIcon';
import CopyLinkIcon from '../Icons/CopyIcon/CopyLinkIcon';
import { generateDate } from '../../shared/functions/date';

type ShipmentSidebarProps = {
  status: ShipmentStatus;
  carrierNames?: string[] | null;
  cost?: ShipmentPrice;
  costBreakDown: ShipmentPriceType[] | null;
  priceRequestNumber?: string | null;
  priceRequestId?: number | null;
  downloadLabels: () => void;
  downloadCmr: () => void;
  downloadPod: () => void;
  fetchFiles: () => void;
  downloadFile: (fileData: ShipmentDocument) => void;
  cmrIsAvailable: boolean;
  shipmentDocuments: ShipmentDocument[];
  podDocuments: ShipmentDocument[];
  shipmentEvents: ShipmentEvent[];
  trackingCode?: string;
  shipmentId: number | undefined;
  claimId: number | undefined;
  claimNumber: string | undefined;
  createdBy: string | undefined;
  labelsEmailSent: string | undefined;
  sentLabelsEmail: string | undefined;
  co2Emission?: string | undefined;
} & BaseProps;

const useStyles = makeStyles({
  shipmentDetailsHeading: {
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
  indicator: {
    marginBottom: theme.spacing(4),
  },
  shipmentStatusView: {
    marginTop: theme.spacing(12),
  },
  strong: {
    fontWeight: 700,
  },
  price: {
    whiteSpace: 'nowrap',
  },
  tooltipIcon: {
    marginLeft: theme.spacing(1),
  },
  actionLink: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '& svg path': {
      fill: 'white',
    },
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  co2: {
    display: 'flex',
    justifyItems: 'center',
    marginLeft: theme.spacing(1),
  },
  tableContainer: {
    overflow: 'hidden',
    boxShadow: 'none',
    '& .MuiTableCell-body': {
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: '17px',
      color: theme.palette.custom.veryDarkGray,
      textOverflow: 'ellipsis',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      verticalAlign: 'initial',
      borderBottomColor: theme.palette.custom.lightGray,
    },
  },
  table: { maxWidth: 910 },
  priceCell: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  priceTooltip: {
    paddingTop: theme.spacing(0.5),
    cursor: 'pointer',
  },
});

const ShipmentSidebar: FC<ShipmentSidebarProps> = ({
  status,
  carrierNames,
  cost,
  priceRequestNumber,
  priceRequestId,
  downloadLabels,
  downloadCmr,
  cmrIsAvailable,
  shipmentDocuments,
  shipmentEvents,
  fetchFiles,
  downloadPod,
  podDocuments,
  downloadFile,
  trackingCode,
  shipmentId,
  claimId,
  claimNumber,
  createdBy,
  labelsEmailSent,
  sentLabelsEmail,
  co2Emission,
  costBreakDown,
  ...props
}): ReactElement => {
  const { t } = useTranslation();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const history = useHistory();
  const { profile } = useUser();
  const user = useUser();
  const [linkCopiedToClipboard, setLinkCopiedToClipboard] = useState<boolean>(false);
  const { priceRequests: priceRequestsFeature } = useFeatures();

  const carrier =
    carrierNames && carrierNames.length ? (
      carrierNames.map((carrierName, index) => (
        <p key={generateKey(index, 'carrier_name')}>{carrierName}</p>
      ))
    ) : (
      <span className={commonClasses.italic}>{t('NOT_KNOWN')}</span>
    );

  const createdTimeEvent: ShipmentEvent[] | null = shipmentEvents
    ? shipmentEvents.filter(
        (item) =>
          item.status?.toLowerCase() === ShipmentStatus[ShipmentStatus.Received].toLowerCase(),
      )
    : null;

  const collectedTimeEvent: ShipmentEvent[] | null = shipmentEvents
    ? shipmentEvents.filter(
        (item) =>
          item.status?.toLowerCase() === ShipmentStatus[ShipmentStatus.PickedUp].toLowerCase(),
      )
    : null;

  const processingTimeEvent: ShipmentEvent[] | null = shipmentEvents
    ? shipmentEvents.filter(
        (item) =>
          item.status?.toLowerCase() === ShipmentStatus[ShipmentStatus.Processing].toLowerCase(),
      )
    : null;

  const deliveredTimeEvent: ShipmentEvent[] | null = shipmentEvents
    ? shipmentEvents.filter(
        (item) =>
          item.status?.toLowerCase() === ShipmentStatus[ShipmentStatus.Delivered].toLowerCase(),
      )
    : null;

  const formatPodLink = (replaceText: string) => {
    return t('DOWNLOAD_POD').replace('{0}', replaceText);
  };

  const cmrBtnTooltip = !cmrIsAvailable ? t('SHIPMENT.CMR_NOT_AVAILABLE') : '';
  const podBtnTooltip = !podDocuments.length ? t('SHIPMENT.POD_NOT_AVAILABLE') : '';
  const downloadCmrBtn = !podDocuments.length
    ? formatPodLink('')
    : formatPodLink(`(${podDocuments.length})`);

  const navigateToPriceRequest = () => {
    if (priceRequestId) {
      history.replace(`${routePriceRequests}/${priceRequestId}`);
    }
  };

  const addShipmentFromCopy = (selectedTemplate: number | undefined) => {
    if (selectedTemplate) {
      history.push(`${routeShipments}/new-shipment?copyShipmentId=${selectedTemplate}`);
    }
  };

  const addClaim = (selectedTemplate: number | undefined) => {
    if (selectedTemplate) {
      history.push(`${routeClaims}/new-claim?shipmentId=${selectedTemplate}`);
    }
  };

  const onSuccessClipboard = () => {
    setLinkCopiedToClipboard(true);
  };
  return (
    <div {...props}>
      <Dialog
        handleClose={() => setLinkCopiedToClipboard(false)}
        maxWidth="sm"
        open={linkCopiedToClipboard}
        title={t('TRACKING_LINK_COPIED')}
      />
      <Typography variant="subtitle2" component="p" className={classes.shipmentDetailsHeading}>
        {t('SHIPMENT_DETAILS')}
      </Typography>
      {profile?.showCarrier && (
        <Indicator
          active
          icon={<TruckIcon />}
          activeColor={theme.palette.secondary.main}
          className={classes.indicator}
        >
          <Typography variant="body1">
            <span className={classes.strong}>{`${t('CARRIER')}:`}</span> {carrier}
          </Typography>
        </Indicator>
      )}
      {profile?.showInShipmentViewPrice && (
        <Indicator
          active
          icon={<DataIcon />}
          activeColor={theme.palette.secondary.main}
          className={classes.indicator}
        >
          <Typography variant="body1">
            <span className={classes.strong}>{`${t('COST')}: `}</span>
            <span className={classes.price}>
              {cost?.selling ? (
                <>
                  {cost.selling}€
                  <Tooltip title={t('COST_TOOLTIP') as string}>
                    <span>
                      <QuestionMarkIcon className={classes.tooltipIcon} />
                    </span>
                  </Tooltip>
                </>
              ) : (
                <span className={commonClasses.italic}>{t('NOT_KNOWN')}</span>
              )}
            </span>
          </Typography>
        </Indicator>
      )}
      {hasPermission(user, UserAction.priceRequests, UserActionType.ViewOnly, 'priceRequest') &&
        priceRequestsFeature &&
        priceRequestId &&
        priceRequestNumber && (
          <Indicator
            active
            icon={<DataIcon />}
            activeColor={theme.palette.secondary.main}
            className={classes.indicator}
          >
            <Typography fontWeight="bold" variant="body1">
              {t('BASED_ON')}:&nbsp;
            </Typography>
            <Typography variant="body1" link onClick={navigateToPriceRequest}>
              {priceRequestNumber}
            </Typography>
          </Indicator>
        )}
      <Indicator
        active
        icon={<NoteIcon />}
        activeColor={theme.palette.secondary.main}
        className={classes.indicator}
      >
        <Typography
          variant="body1"
          customColor={!cmrIsAvailable ? theme.palette.custom.gray : undefined}
          link
          onClick={downloadCmr}
        >
          <Tooltip title={cmrBtnTooltip} arrow>
            <>{t('DOWNLOAD_CMR')}</>
          </Tooltip>
        </Typography>
      </Indicator>
      <Indicator
        active
        icon={<LabelIcon />}
        activeColor={theme.palette.secondary.main}
        className={classes.indicator}
      >
        <Typography variant="body1" link onClick={() => downloadLabels()}>
          {t('DOWNLOAD_LABELS')}
        </Typography>
      </Indicator>
      <Indicator
        active
        icon={<PodIcon />}
        activeColor={theme.palette.secondary.main}
        className={classes.indicator}
      >
        <Typography
          variant="body1"
          link
          onClick={downloadPod}
          customColor={!podDocuments.length ? theme.palette.custom.gray : undefined}
        >
          <Tooltip title={podBtnTooltip} arrow>
            <span>{downloadCmrBtn}</span>
          </Tooltip>
        </Typography>
      </Indicator>
      {trackingCode && (
        <Indicator
          active
          icon={<CopyLinkIcon />}
          activeColor={theme.palette.secondary.main}
          className={classes.indicator}
        >
          <Clipboard
            onSuccess={() => onSuccessClipboard()}
            data-clipboard-text={`${window.origin}/tracking/${trackingCode}`}
            component="span"
          >
            <Typography variant="body1" link onClick={() => () => {}}>
              {t('COPY_TRACKING_LINK')}
            </Typography>
          </Clipboard>
        </Indicator>
      )}
      {hasPermission(user, UserAction.claims, UserActionType.ViewOnly, 'claims') && (
        <Indicator
          active
          icon={<ClaimIcon />}
          activeColor={theme.palette.secondary.main}
          className={classes.indicator}
        >
          {!claimId ? (
            <Typography
              className={clsx(
                !hasPermission(user, UserAction.claims, UserActionType.Edit, 'claims') &&
                  commonClasses.hidden,
              )}
              variant="body1"
              link
              onClick={() => addClaim(shipmentId)}
            >
              <Tooltip title={t('ADD_NEW_CLAIM').toString()} arrow>
                <>{t('ADD_NEW_CLAIM')}</>
              </Tooltip>
            </Typography>
          ) : (
            <Typography variant="body1" component="a" link href={`${routeClaims}/${claimId}`}>
              <Tooltip title={t('CLAIMS').toString()} arrow>
                <span>{claimNumber}</span>
              </Tooltip>
            </Typography>
          )}
        </Indicator>
      )}
      {profile?.showInShipmentViewPrice && costBreakDown && costBreakDown.length > 0 && (
        <>
          <Typography
            variant="subtitle2"
            marginTop={7}
            marginBottom={1}
            component="p"
            className={classes.shipmentDetailsHeading}
          >
            {t('COST_BREAKDOWN')}
          </Typography>
          <Box>
            <TableContainer className={classes.tableContainer}>
              <Table className={classes.table} aria-label="shipment costs">
                <TableBody>
                  {costBreakDown.map((item, index) => {
                    return (
                      <TableRow key={generateKey(index, 'cost')}>
                        <TableCell className={classes.priceCell}>
                          {`${item.type ? t(item.type.toString()) : item.description}`}
                          {item.type && item.description && (
                            <Tooltip title={item.description}>
                              <span className={classes.priceTooltip}>
                                <InfoIcon className={classes.tooltipIcon} />
                              </span>
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">{item.cost}€</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
      {profile?.showCo2Emission && (
        <>
          <Typography
            variant="subtitle2"
            marginTop={7}
            marginBottom={1}
            component="p"
            className={classes.shipmentDetailsHeading}
          >
            CO<sub>2</sub> {t('CO2_EMISSIONS')}
            <Tooltip title={t('CO2_EMISSIONS_TOOLTIP') as string}>
              <span>
                <QuestionMarkIcon className={classes.tooltipIcon} />
              </span>
            </Tooltip>
          </Typography>
          <Typography variant="body1" className={classes.co2}>
            <Co2LeafIcon height={20} width={20} /> {!co2Emission ? '–' : co2Emission} kg
          </Typography>
        </>
      )}

      {sentLabelsEmail && (
        <>
          <Typography
            variant="subtitle2"
            marginTop={7}
            component="p"
            className={classes.shipmentDetailsHeading}
          >
            {t('LABELS_SENT_DETAILS')}
          </Typography>
          <Typography variant="body2" fontStyle="italic">
            {labelsEmailSent && generateDate(labelsEmailSent, 'dd.MM.yyyy H:ii')} /{' '}
            {sentLabelsEmail}
          </Typography>
        </>
      )}
      <ShipmentStatusOverview
        status={status}
        shipmentEvents={shipmentEvents}
        createdBy={createdBy}
        className={classes.shipmentStatusView}
        createdTime={
          createdTimeEvent && createdTimeEvent[0] ? createdTimeEvent[0].dateString : undefined
        }
        collectedTime={
          collectedTimeEvent && collectedTimeEvent[0] ? collectedTimeEvent[0].dateString : undefined
        }
        processingTime={
          processingTimeEvent && processingTimeEvent[0]
            ? processingTimeEvent[0].dateString
            : undefined
        }
        deliveredTime={
          deliveredTimeEvent && deliveredTimeEvent[0] ? deliveredTimeEvent[0].dateString : undefined
        }
      />
      <ShipmentDocuments
        documents={shipmentDocuments}
        fetchFiles={fetchFiles}
        downloadFile={downloadFile}
        fileObject="Shipment"
      />
      {hasPermission(user, UserAction.shipments, UserActionType.Edit, 'shipments') && (
        <>
          {' '}
          <Typography
            variant="subtitle2"
            component="p"
            marginTop={12}
            className={classes.shipmentDetailsHeading}
          >
            {t('SHIPMENT.SIDEBAR.ACTIONS')}
          </Typography>
          <Indicator
            active
            icon={<CopyIcon width={16} height={22} />}
            className={classes.actionLink}
          >
            <Typography variant="body2" onClick={() => addShipmentFromCopy(shipmentId)}>
              {t('COPY_SHIPMENT')}
            </Typography>
          </Indicator>
        </>
      )}
    </div>
  );
};

export default ShipmentSidebar;
