import { ReactElement } from 'react';
import { LoadingContact } from 'shared/types/loadingContact';
import { ApiBaseProps } from 'shared/types/api';
import { ShipmentItemSubType } from './shipmentItem';
import {
  PriceRequestDetailsPayload,
  PriceRequestOfferedPrice,
} from '../priceRequests/priceRequests';
import { DashoardShipment } from './dashoardShipment';

export type DatatableShipment = {
  id: number;
  templateName: string;
  number: string;
  reference: string;
  amount: string | ReactElement;
  carrier: string;
  createdOn: string;
  pickup: string;
  isConfirmedFixedPickupDate: boolean;
  isFixedPickupDate: boolean;
  confirmedEarliestPickupDate: string;
  earliestPickupDate: string;
  earliestPickupTime: string;
  confirmedLatestPickupDate: string;
  latestPickupDate: string;
  latestPickupTime: string;
  isConfirmedFixedArrivalDate: boolean;
  isFixedArrivalDate: boolean;
  confirmedEarliestArrivalDate: string;
  earliestArrivalDate: string;
  earliestArrivalTime: string;
  confirmedLatestArrivalDate: string;
  latestArrivalDate: string;
  latestArrivalTime: string;
  destination: string;
  status: ShipmentStatus;
  statusText: string;
  isCMRAvailable: boolean;
  hasFile: boolean;
  hasComment: boolean;
  newOperatorComments: number;
  loadingAddressCountry: string;
  deliveryAddressCountry: string;
  loadingAddressPostCode: string;
  deliveryAddressPostCode: string;
  senderCompany: string;
  receiverCompany: string;
  cost: string;
  type: number;
  labelPrinted: string;
  cmrPrinted: string;
  pieces: string;
  isFavoriteShipment: boolean;
  co2Emission: string;
};

export type Shipment = {
  carrier: string;
  company: string;
  cost: number;
  createdBy: number | null;
  createdByName: string;
  createdOn: string;
  debtorCompany: string;
  deliveryAddressCountry: string;
  deliveryAddressPostCode: string;
  destination: string;
  hasComment: boolean;
  hasFile: boolean;
  hasPodFiles: boolean;
  id: number;
  isCMRAvailable: boolean;
  latestComment: unknown;
  latestLocation: unknown;
  ldm: number;
  loadingAddressCountry: string;
  loadingAddressPostCode: string;
  number: string;
  objectId: number;
  pickup: string;
  isConfirmedFixedPickupDate: boolean;
  isFixedPickupDate: boolean;
  confirmedEarliestPickupDate: string;
  earliestPickupDate: string;
  earliestPickupTime: string;
  confirmedLatestPickupDate: string;
  latestPickupDate: string;
  latestPickupTime: string;
  isConfirmedFixedArrivalDate: boolean;
  isFixedArrivalDate: boolean;
  confirmedEarliestArrivalDate: string;
  earliestArrivalDate: string;
  earliestArrivalTime: string;
  confirmedLatestArrivalDate: string;
  latestArrivalDate: string;
  latestArrivalTime: string;
  quantity: number;
  receiverCompany: string;
  reference: string;
  senderCompany: string;
  shortDeliveryAddress: string;
  shortLoadingAddress: string;
  status: string;
  totalWeight: number;
  trackerId: number;
  volume: number;
  templateName: string;
  newOperatorComments: number;
  type: number;
  labelPrinted?: Date | null;
  cmrPrinted?: Date | null;
  piecesList: PiecesListViewModel[];
  isFavoriteShipment: boolean;
  co2Emission: string;
};

export type PiecesListViewModel = {
  type: ShipmentItemSubType;
  quantity: number;
  packageId?: string;
};
export type ShipmentsPayload = {
  contextObjects?: Shipment[];
  pages: number;
  totalCount: number;
};
export type DashboardShipmentsPayload = {
  contextObjects?: DashoardShipment[];
  pages: number;
  totalCount: number;
};
export type ShipmentsApiRequest = {
  page?: number;
  sizePerPage?: number;
  createdByMe?: boolean;
  sortName?: string;
  sortOrder?: string;
  filters?: IShipmentsFilters;
} & ApiBaseProps;

export type ShipmentApiRequest = {
  id: number;
} & ApiBaseProps;

export type ShipmentTrackingApiRequest = {
  trackingCode: string;
} & ApiBaseProps;

export type UnSavedShipmentApiRequest = {
  payload: NewShipmentPayload;
  id: string;
} & ApiBaseProps;

export type SaveShipmentApiRequest = {
  payload: NewShipmentPayload | PriceRequestDetailsPayload;
  draft?: boolean;
} & ApiBaseProps;

export type ShipmentLabelsRequest = {
  shipmentId: number;
} & ApiBaseProps;

export type ShipmentPayload = {
  id: number;
  carrierNames?: string[] | null;
  costDetails: ShipmentPriceType[] | null;
  companyId: 1;
  createdBy: string;
  createdOn: string;
  labelsEmailSent: string | undefined;
  co2Emission: string | undefined;
  sentLabelsEmail: string | undefined;
  debtorCompanyId: number;
  deliveredComment: string;
  deliveredDate: string | null;
  deliveredSignBy: string;
  deliveryReference: string;
  destinationAddress: string;
  earliestArrivalDate: string | null;
  confirmedEarliestArrivalDate: string | null;
  earliestArrivalTime: string | null;
  earliestPickupDate: string | null;
  confirmedEarliestPickupDate: string | null;
  earliestPickupTime: string | null;
  expectedPartnerId: number | null;
  expectedPrice: number | null;
  invoiceId: number | null;
  latestArrivalDate: string | null;
  confirmedLatestArrivalDate: string | null;
  latestArrivalTime: string | null;
  latestPickupDate: string | null;
  confirmedLatestPickupDate: string | null;
  latestPickupTime: string | null;
  loadingReference: string;
  monthlyPlanId: number | null;
  number: string;
  originAddress: string;
  otherInstructions: string;
  priceRequestId: number | null;
  printableInformation: string;
  receiver: LoadingContact;
  receiverAlternative?: LoadingContact | null;
  reference: string;
  sellingPrice: number | null;
  sender: LoadingContact;
  senderAlternative?: LoadingContact | null;
  shipmentDetailsRows: ShipmentDetailsRow[];
  status: ShipmentStatus;
  type: ShipmentType;
  templateName?: string;
  tenantId: number;
  terminalId: number | null;
  totalEur: number;
  totalLdm: number;
  totalQuantity: number;
  totalVolume: number;
  totalWeight: number;
  transportCompany: string;
  transportUnit: unknown;
  updatedBy: string;
  updatedOn: string;
  useReceiverAlternative: boolean;
  useSenderAlternative: boolean;
  isCMRAvailable: boolean;
  tailLiftTruck?: boolean;
  tailLiftTruckDelivery?: boolean;
  isFixedPickupDate: boolean;
  isFixedArrivalDate: boolean;
  offeredPrices?: PriceRequestOfferedPrice[];
  selectedPriceOffer?: string | number | null;
  trackingCode?: string;
  events?: ShipmentEvent[];
  claimId: number | undefined;
  claimNumber: string | undefined;
  isFavoriteShipment: boolean;
};

export type NewShipmentPayload = {
  id?: number;
  deliveryReference?: string | null;
  earliestArrivalDate: string | null;
  earliestPickupDate: string | null;
  earliestArrivalTime: string | null;
  earliestPickupTime: string | null;
  latestArrivalDate: string | null;
  latestPickupDate: string | null;
  latestArrivalTime: string | null;
  latestPickupTime: string | null;
  loadingReference?: string | null;
  isFixedPickupDate: boolean;
  isFixedArrivalDate: boolean;
  otherInstructions?: string | null;
  tailLiftTruck?: boolean;
  tailLiftTruckDelivery?: boolean;
  receiver: LoadingContact | null;
  receiverAlternative?: LoadingContact | null;
  sender: LoadingContact | null;
  senderAlternative?: LoadingContact | null;
  shipmentDetailsRows: ShipmentDetailsRow[];
  tenantId: number | null;
  type?: number | null;
  useReceiverAlternative: boolean;
  useSenderAlternative: boolean;
  reference?: string | null;
  templateName?: string | null;
  number?: string | null;
  priceRequestId?: number | null;
  offeredPrices?: PriceRequestOfferedPrice[];
  selectedPriceOffer?: string | number | null;
  templateId?: number;
  autoSavedObject?: string;
  status?: number;
};

export type DangerousGoodsInfo = {
  classVal?: string;
  pg?: string;
  properShippingName?: string;
  un?: string;
};

export type ShipmentDetailsRow = {
  coldFrozen?: boolean | null;
  dangerousGoods?: boolean | null;
  dangerousGoodsInfo?: DangerousGoodsInfo[];
  delicateGoods?: boolean | null;
  height?: number;
  id?: number;
  lastTrackerLocation?: unknown;
  lastTrackerLocationTime?: unknown;
  ldm?: number | null;
  length?: number;
  loadingMeters?: number;
  packageId?: number | null;
  productAdditionalInfo?: unknown;
  productDescription?: string;
  quantityId?: number;
  reference?: string | null;
  remarks?: unknown;
  spxNumber?: number | null;
  spxVal?: number | null;
  stackable?: boolean;
  temperature?: number | null;
  temperatureMax?: number | null;
  totalWeight?: number;
  tracker?: unknown;
  typeId?: ShipmentItemSubType;
  volume?: number | null;
  weight?: number;
  width?: number;
  tailLiftTruck?: boolean;
};

export type IShipmentsFilters = {
  [key: string]: string | string[] | number | undefined;
  keyword?: string;
  type?: number;
  period?: 'all' | 'yesterday' | 'today' | 'tomorrow' | undefined;
  number?: string;
  reference?: number | string;
  pickupCountries?: string[];
  pickUpDateFrom?: string;
  pickUpDateTo?: string;
  deliveryCountries?: string[];
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  arrivalDateFrom?: string;
  arrivalDateTo?: string;
  statuses?: string[];
  consignee?: string;
  consignor?: string;
  forWarehouse?: string;
};

export type ShipmentDocument = {
  id: number;
  fileName: string;
  createdOn: string;
  url: string;
  size: number;
  allowDelete: boolean;
  file?: File | undefined;
  isLocked: boolean;
  allowToggleLocking: boolean;
  createdBy?: string;
};

export enum ShipmentStatus {
  NotSelected,
  Draft,
  WaitingForConfirmation = 3,
  Canceled = 5,
  MissingData = 10,
  Received = 15,
  Confirmed = 20,
  Processing = 25,
  PickedUp = 30,
  InTransit = 40,
  Delivering = 45,
  Delivered = 50,
  LastDelivered = 60,
  WaitingReadinessRequest = 70,
}
export enum ShipmentType {
  NotSelected = 0,
  Sender = 5,
  Receiver = 10,
}

export type ShipmentEvent = {
  status?: string | null;
  dateString: string;
  description: string;
  location: string;
};

export type ShipmentPrice = {
  selling: number | null;
  purchase: number | null;
};
export type ShipmentPriceType = {
  cost: number | null;
  type: string | null;
  description: string;
};
export type ShipmentAddressType = 'receiver' | 'sender';
export type ShipmentDateType = 'Pickup' | 'Arrival';
export type ShipmentAddressAlternativeType = 'receiverAlternative' | 'senderAlternative';
export type ShipmentAddressAlternativeUseType = 'useReceiverAlternative' | 'useSenderAlternative';
export type ShipmentAddress = {
  address: LoadingContact;
  alternativeAddress?: LoadingContact | null;
};

export type ShipmentCommentsApiRequest = {
  id: number;
  payload?: NewShipmentCommentPayload;
  object: 'Shipment' | 'PriceRequest';
} & ApiBaseProps;

export type NewShipmentCommentPayload = {
  comment: string;
  isPublic: boolean;
  operatorRead: string | null;
  customerRead: string | null;
};

export type ShipmentUpdateCommentApiRequest = {
  id: number;
  payload?: ShipmentUpdateCommentPayload;
  object: 'Shipment' | 'PriceRequest';
} & ApiBaseProps;

export type ShipmentUpdateCommentPayload = {
  operatorRead: string | null;
  customerRead: Date | null;
};

export type ShipmentOperatorCommentsPerEntityPayload = {
  commentCount: number;
  objectCode: string;
  objectId: number;
  objectType: string;
};

export type ShipmentCommentCountPayload = {
  unreadCustomerComments: number | null;
  unreadCustomerCommentsPerEntity: number | null;
  unreadOperatorComments: number | null;
  unreadOperatorCommentsPerEntity: ShipmentOperatorCommentsPerEntityPayload[];
};

export type ShipmentCommentsPayload = {
  id: number;
  comment: string;
  isPublic: boolean;
  canDelete: boolean;
  operatorReadUTCString: string | null;
  operatorRead: string | null;
  customerReadUTCString: string | null;
  customerRead: string | null;
  createdBy: string;
  createByName: string;
  objectNumber: string;
  objectId: number;
  createdOn: string;
  object: 'Shipment' | 'PriceRequest';
  objectType: number;
}[];

export type ShipmentCommentDataTable = {
  id: number;
  objectId: number;
  comment: string;
  objectNumber: string;
  isPublic: boolean;
  canDelete: boolean;
  operatorReadUTCString: string;
  operatorRead: string;
  customerReadUTCString: string;
  customerRead: string;
  createdBy: string;
  createdOn: string;
  object: 'Shipment' | 'PriceRequest';
};

export type ShipmentCommentPayload = {
  id: number;
  comment: string;
  isPublic: boolean;
  canDelete: boolean;
  operatorReadUTCString: string | null;
  operatorRead: string | null;
  customerReadUTCString: string | null;
  customerRead: string | null;
  createdBy: string;
  createdOn: string;
  object: 'Shipment' | 'PriceRequest';
};

export type ShipmentsExcelImportPayload = {
  duplicateRows?: NewShipmentPayload[];
  duplicateRowsCount: number;
  malformedRowsCount: number;
  correctRowsCount: number;
  validationErrors: ShipmentsExcelImportErrorPayload[];
  exists: ShipmentExistsExcelImportErrorPayload[];
  correctFileReference: string;
  issuesFileReference: string;
};

export type ShipmentExistsExcelImportErrorPayload = {
  reference: string;
  id: number;
  createdOn: string | null;
  row: number;
};

export type ShipmentsExcelImportErrorPayload = {
  reference: string;
  errors: ShipmentsExcelImportRowErrorPayload[];
  doNotTranslate?: boolean;
};

export type ShipmentsExcelImportRowErrorPayload = {
  field: string;
  row: number;
  message: string;
};
